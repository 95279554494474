import { NgModule } from '@angular/core';

import { StepsModule } from 'primeng/steps';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { StepperModule } from 'primeng/stepper';
import { InputTextModule } from 'primeng/inputtext';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputOtpModule } from 'primeng/inputotp';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { MessageService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { MultiSelectModule } from 'primeng/multiselect';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { StyleClassModule } from 'primeng/styleclass';
import { DragDropModule } from 'primeng/dragdrop';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { CardModule } from 'primeng/card';

@NgModule({
  imports: [
    StepsModule,
    ButtonModule,
    FloatLabelModule,
    DropdownModule,
    CalendarModule,
    StepperModule,
    InputTextModule,
    ToggleButtonModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    ToastModule,
    SidebarModule,
    MenuModule,
    CheckboxModule,
    AvatarModule,
    MultiSelectModule,
    RatingModule,
    RippleModule,
    TableModule,
    TagModule,
    StyleClassModule,
    DragDropModule,
    InputSwitchModule,
    RadioButtonModule,
    InputGroupModule,
    InputGroupAddonModule,
    DialogModule,
    TooltipModule,
    CardModule
  ],
  exports: [
    StepsModule,
    ButtonModule,
    FloatLabelModule,
    DropdownModule,
    CalendarModule,
    StepperModule,
    InputTextModule,
    ToggleButtonModule,
    IconFieldModule,
    InputIconModule,
    InputOtpModule,
    ToastModule,
    SidebarModule,
    CheckboxModule,
    MenuModule,
    AvatarModule,
    MultiSelectModule,
    RatingModule,
    RippleModule,
    TableModule,
    TagModule,
    StyleClassModule,
    DragDropModule,
    InputSwitchModule,
    RadioButtonModule,
    InputGroupModule,
    InputGroupAddonModule,
    DialogModule,
    TooltipModule,
    CardModule
  ],
  providers: []
})
export class PrimengModule { }
