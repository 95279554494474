import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth/auth.service';
import { ApiService } from '../../shared/services/api/api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  public show: boolean = false;

  email: string = '';
  password: string = '';
  rememberMe: boolean = false;
  errorLogin: string = '';
  constructor(
    private _authService : AuthService,
    private _apiService: ApiService,
    private _router: Router,
  ) { }

  ngOnInit() {
    if(this._authService.authenticated()){
      this._router.navigate(["dashboard"]);
    }
  }

  showPassword() {
    this.show = !this.show;
  }

  login(){
    var formData: any = new FormData();
    formData.append("email", this.email);
    formData.append("password", this.password);
    this._authService.login(formData);
    this._authService.loginSuccess.subscribe((success: boolean) => {
      if(success) {
        this.errorLogin = "";
      } else {
        this.errorLogin = "Echec de la connexion";
      }
    });
  }

  onKeydown(event: { key: string; }, email: string, password: string) {
    if (event.key === "Enter") {
      this.login();
    }
  }

  onSubmit() {
  }

}
