
<div class="layout-sidebar">
  <div class="layout-menu-container">
      <div class="sidebar-header">
          <img src="/assets/images/logo-onati-blanc.png" alt="Logo Vini" class="img-fluid" width="80%" style="max-width: 50px;">
      </div>
      <ul class="layout-menu">
        <li class="menu-item" routerLinkActive="active">
            <a class="menu-link" [routerLink]="'/dashboard'">
              <i class="fa-light fa-gauge"></i> <span>Tableau de bord</span>
            </a>
        </li>
      </ul>
      <div class="menu-item menu-item-title flex align-items-center" routerLinkActive="active">
        <span>Dossiers Clients</span>
      </div>
      <ul class="layout-menu">
        <li class="menu-item" routerLinkActive="active">
          <a class="menu-link" [routerLink]="'/dossiers'">
            <i class="fa-light fa-sim-cards"></i> <span>Dossiers</span>
          </a>
        </li>
      </ul>
      <div class="menu-item menu-item-title flex align-items-center" routerLinkActive="active">
        <span>Utilisateurs</span>
      </div>
      <ul class="layout-menu">
        <li class="menu-item" routerLinkActive="active">
          <a class="menu-link" [routerLink]="'/partners'">
            <i class="fa-light fa-user-cowboy"></i> <span>Partenaires</span>
          </a>
        </li>
        <li class="menu-item" routerLinkActive="active">
          <a class="menu-link" [routerLink]="'/agents'">
            <i class="fa-light fa-user-headset"></i> <span>Agent</span>
          </a>
        </li>
      </ul>
      <div class="menu-item menu-item-title flex align-items-center" routerLinkActive="active">
        <span>Tirage au sort</span>
      </div>
      <ul class="layout-menu">
        <li class="menu-item" routerLinkActive="active">
          <a class="menu-link" [routerLink]="'/gifts'">
            <i class="fa-light fa-gift"></i> <span>Lots</span>
          </a>
        </li>
      </ul>
  </div>
  <div style="background-color: #005779; height: 70px; border: 0" class="d-flex align-items-center justify-content-between px-4">
      <div class="dropdown">
          <a class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="true">
              <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30" class="rounded-circle">
              <span class="username d-none d-sm-inline mx-1">{{user ? (user?.firstname + ' ' + user?.lastname) : ''}}</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-dark text-small" id="dropdownUser1">
              <li><a class="dropdown-item" href="#">Profile</a></li>
              <li>
                  <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" (click)="logout()">Déconnexion</a></li>
          </ul>
      </div>
  </div>
</div>
