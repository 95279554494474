<div class="login-container" style="background-color: #005779;">
    <div class="card login-card">
      <div class="text-center my-4">
        <img src="https://www.zuckoo.pf/_next/image?url=https%3A%2F%2Fzuckoo-cabinetlevy.pantheonsite.io%2Fsites%2Fdefault%2Ffiles%2F2022-08%2F1635471446726.jpg&w=384&q=75"
            alt="Logo Vini" class="img-fluid" width="100" height="100">
      </div>
      <form (ngSubmit)="onSubmit()">
        <div class="p-fluid">
          <div class="p-field mt-4">
            <label for="email">Email</label>
            <input id="email" type="email" pInputText [(ngModel)]="email" name="email" required>
          </div>
          <div class="p-field mt-4">
            <label for="password">Mot de passe</label>
            <input id="password" type="password" pInputText [(ngModel)]="password" name="password" required>
          </div>
          <!-- <div class="p-field-checkbox mt-4">
            <p-checkbox [(ngModel)]="rememberMe" name="rememberMe" binary="true"></p-checkbox>
            <label for="rememberMe" class="ms-2">Rester connecté</label>
          </div> -->
          <div class="p-field mt-4">
            <button style="background-color: #005779" pButton type="submit" label="Se Connecter" class="p-mt-2" (click)="login()"></button>
          </div>
          <label *ngIf="errorLogin != ''" for="errorLogin" class="mt-2" style="color: red;">{{errorLogin}}</label>
        </div>
      </form>
    </div>
  </div>
  