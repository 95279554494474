export class Columns {
  private _id: number;
  private _name: string;
  private _field: string;
  private _children: Columns[];
  private _sorting: string;

  constructor(id: number, field: string, name: string,  sorting: string, children: Columns[] = []) {
    this._id = id;
    this._field = field;
    this._name = name;
    this._children = children;
    this._sorting = sorting;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get field(): string {
    return this._field;
  }

  set field(value: string) {
    this._field = value;
  }

  get sorting(): string {
    return this._sorting;
  }

  set sorting(value: string) {
    this._sorting = value;
  }

  get children(): Columns[] {
    return this._children;
  }

  set children(value: Columns[]) {
    this._children = value;
  }

  // Méthode statique pour créer les données initiales
  static createInitialDataHome(): Columns[] {
    return [
      new Columns(1, 'offer', "Offre prépayée", "asc"),
      new Columns(3, 'sim_number', "N° Carte SIM", "asc"),
      new Columns(4, 'phone', "N° Contrat NOA", "asc")
    ];
  }
  // Méthode statique pour créer les données initiales
  static createInitialData(): Columns[] {
    return [
      new Columns(1, '', 'Données de la ligne', '', [
        new Columns(0, 'token', "Token", "asc"),
        new Columns(8, 'phone', "N° Mobile de l'offre prépayée", "asc"),
        new Columns(9, 'offer', "Offre prépayée", "asc"),
        new Columns(10, 'sim_number', "N° Carte SIM", "asc"),
        new Columns(11, 'noa_contract_number', "N° Contrat NOA", "asc"),
        new Columns(12, 'phone_line_status', 'Statut de la ligne', "asc"),
        new Columns(7, 'sim_activation_date', "Date d'activation SIM", "asc"),
        new Columns(13, 'dateActionSim', 'Délai avant action', "asc")
      ]),
      new Columns(2, '', 'Données saisies par le client', '', [
        new Columns(14, '', "Nom", "asc"),
        new Columns(15, '', 'Prénom', "asc"),
        new Columns(16, '', 'Date de naissance', "asc")
      ]),
      new Columns(3, '', 'Données Trust & Sign','', [
        new Columns(17, '', "Date de création du dossier Trust & Sign", "asc"),
        new Columns(18, '', 'Evénement (dernier affiché)', "asc"),
        new Columns(19, '', 'Nom', "asc"),
        new Columns(20, '', 'Prénom', "asc"),
        new Columns(21, '', 'Date de naissance', "asc"),
        new Columns(22, '', "Type pièce d'identité (PI)", "asc"),
        new Columns(23, '', "N° PI", "asc"),
        new Columns(24, '', 'Statut Trust & Sign', "asc"),
        new Columns(25, '', "Bouton ou lien d'accès au dossier Trust & Sign", "asc")
      ]),
      new Columns(4, '', 'Données NOA', '',[
        new Columns(26, '', "Résultats de la recherche CUST", "asc"),
        new Columns(27, '', 'Statut saisie NOA', "asc")
      ]),
      new Columns(5, '', 'Saisie agent ADV','', [
        new Columns(28, '', 'Statut ADV', "asc"),
        new Columns(29, '', "Agent de saisie", "asc"),
        new Columns(30, '', 'Date de saisie', "asc")
      ]),
      new Columns(6, '', 'Tirage au sort','', [
        new Columns(31, '', "Statut", "asc"),
        new Columns(32, '', 'Date', "asc")
      ])
    ];
  }
}
