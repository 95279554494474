import { Injectable, InjectionToken, Inject, PLATFORM_ID, ɵɵdefineInjectable, ɵɵinject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { lib, PBKDF2, AES, enc } from 'crypto-js';

/**
 * @fileoverview added by tsickle
 * Generated from: lib/ngx-encrypt-cookie.service.ts
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class NgxEncryptCookieService {
  /**
   * @param {?} platformId
   */
  constructor(platformId) {
    this.platformId = platformId;
    this.documentIsAccessible = isPlatformBrowser(this.platformId);
  }
  /**
   * @param {?=} keySize Cookie name
   * @param {?=} passPhrase secret passPhrase
   * @return {?} returns key when setting cookie and getting cookie
   * default keySize is 128/32 and default passPhrase is "Secret PassPhrase"
   */
  generateKey(keySize, passPhrase) {
    /** @type {?} */
    var salt = lib.WordArray.random(128 / 8);
    /** @type {?} */
    var keySizeBytes;
    /** @type {?} */
    var secrtePassPhrase;
    keySize ? keySizeBytes = keySize : keySizeBytes = "128/32";
    passPhrase ? secrtePassPhrase = passPhrase : secrtePassPhrase = "Secret PassPhrase";
    switch (keySizeBytes) {
      case "128/32":
        /** @type {?} */
        var msg = PBKDF2(secrtePassPhrase, salt, {
          keySize: 128 / 32
        });
        return msg.toString();
        break;
      case "256/32":
        /** @type {?} */
        var msg = PBKDF2(secrtePassPhrase, salt, {
          keySize: 256 / 32
        });
        return msg.toString();
        break;
      case "512/32":
        /** @type {?} */
        var msg = PBKDF2(secrtePassPhrase, salt, {
          keySize: 512 / 32
        });
        return msg.toString();
        break;
    }
  }
  /**
   * encrypt() is calling when set() calls
   * @private
   * @param {?} val value to store in cookies
   * @param {?} secret_key is a key generated by using generateKey() or user defined key.
   * @return {?} encrypted val to set() and there the cookie will set. if user doesnt generateKey or pass key error will be thrown.
   */
  encrypt(val, secret_key) {
    if (secret_key != null || secret_key != "" || secret_key.length > 0) {
      /** @type {?} */
      var encrypt_msg = AES.encrypt(val, secret_key);
      return encrypt_msg;
    } else {
      console.error("Pass Secret key to set cookie");
    }
  }
  /**
   *
   * @private
   * @param {?} cookieName
   * @param {?} encrypted boolean - cookie stored having encrypted val or not
   * @param {?=} secret_key is key which is used to encrypt cookie val. it is not required if encrypted is false
   * @return {?}
   */
  decrypt(cookieName, encrypted, secret_key) {
    if (this.documentIsAccessible && this.check(cookieName)) {
      cookieName = encodeURIComponent(cookieName);
      /** @type {?} */
      const regExp = this.getCookieRegExp(cookieName);
      /** @type {?} */
      const result = regExp.exec(document.cookie);
      if (encrypted) {
        if (secret_key) {
          /** @type {?} */
          let encrypt_msg = this.safeDecodeURIComponent(result[1]);
          /** @type {?} */
          let decrypt_msg = AES.decrypt(encrypt_msg, secret_key);
          /** @type {?} */
          let message = decrypt_msg.toString(enc.Utf8);
          return message;
        } else {
          console.error("pass secret key to get cookie value");
        }
      } else {
        return this.safeDecodeURIComponent(result[1]);
      }
    } else {
      return '';
    }
  }
  /**
   * @param {?} cookieName Cookie name
   * @return {?} boolean  whether cookie with specified name is existed or not
   */
  check(cookieName) {
    if (!this.documentIsAccessible) {
      return false;
    }
    /** @type {?} */
    var name = encodeURIComponent(cookieName);
    /** @type {?} */
    const regExp = this.getCookieRegExp(name);
    /** @type {?} */
    const exists = regExp.test(document.cookie);
    return exists;
  }
  /**
   *
   * @param {?} cookieName cookie name
   * @param {?} encryption boolean - whether to want encrypted or decrypted value.
   * @param {?=} key - it should enter if encrypted=true otherwise error will be thrown.key can be either generated using generateKey() or
   * user definded key
   * @return {?}
   */
  get(cookieName, encryption, key) {
    /** @type {?} */
    var val;
    if (encryption) {
      if (key) {
        val = this.decrypt(cookieName, encryption, key);
        return val;
      }
    }
    // if key is not passed or encrypted = false;
    val = this.decrypt(cookieName, false, null);
    return val;
  }
  /**
   * @param {?=} encrypted boolean - to know encrypted values are there
   * @param {?=} key  generatedKey() or user defined key - to decrypt encrypted values
   * @return {?} cookies - all the cookies  stored
   */
  getAll(encrypted, key) {
    if (!this.documentIsAccessible) {
      return {};
    }
    /** @type {?} */
    const cookies = {};
    if (encrypted) {
      if (document.cookie && document.cookie !== '') {
        document.cookie.split(';').forEach(
        /**
        * @param {?} currentCookie
        * @return {?}
        */
        currentCookie => {
          const [cookieName, cookieValue] = currentCookie.split('=');
          /** @type {?} */
          var cookie_name = this.safeDecodeURIComponent(cookieName.replace(/^ /, ''));
          /** @type {?} */
          var cookie_val = this.get(cookie_name, encrypted, key);
          cookies[this.safeDecodeURIComponent(cookieName.replace(/^ /, ''))] = cookie_val;
          // cookies[]
        });
      }
    } else {
      if (document.cookie && document.cookie !== '') {
        document.cookie.split(';').forEach(
        /**
        * @param {?} currentCookie
        * @return {?}
        */
        currentCookie => {
          const [cookieName, cookieValue] = currentCookie.split('=');
          /** @type {?} */
          var cookie_name = this.safeDecodeURIComponent(cookieName.replace(/^ /, ''));
          cookies[this.safeDecodeURIComponent(cookieName.replace(/^ /, ''))] = this.safeDecodeURIComponent(cookieValue);
          // cookies[]
        });
      }
    }
    return cookies;
  }
  /**
   * @private
   * @return {?}
   */
  getAllCookies() {
    if (!this.documentIsAccessible) {
      return {};
    }
    /** @type {?} */
    const cookies = {};
    if (document.cookie && document.cookie !== '') {
      document.cookie.split(';').forEach(
      /**
      * @param {?} currentCookie
      * @return {?}
      */
      currentCookie => {
        const [cookieName, cookieValue] = currentCookie.split('=');
        /** @type {?} */
        var cookie_name = this.safeDecodeURIComponent(cookieName.replace(/^ /, ''));
        cookies[this.safeDecodeURIComponent(cookieName.replace(/^ /, ''))] = this.safeDecodeURIComponent(cookieValue);
        // cookies[]
      });
    }
    return cookies;
  }
  /**
   * @param {?} name     Cookie name
   * @param {?} value    Cookie value
   * @param {?} encrypt  boolean - to encrypt cookie value or not
   * @param {?=} key      a key can either generate using generateKey() or user defined key
   * @param {?=} expires  Number of days until the cookies expires or an actual `Date`
   * @param {?=} path     Cookie path (eg:"/")
   * @param {?=} domain   Cookie domain (eg:"domain.com")
   * @param {?=} secure   Secure flag
   * @param {?=} sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
   * @return {?}
   */
  set(name, value, encrypt, key, expires, path, domain, secure, sameSite = 'Lax') {
    /** @type {?} */
    var cookieString;
    if (!this.documentIsAccessible) {
      return;
    }
    if (encrypt) {
      if (key) {
        /** @type {?} */
        let encrypted_msg = this.encrypt(value, key);
        cookieString = encodeURIComponent(name) + '=' + encodeURIComponent(encrypted_msg) + ';';
      } else {
        console.error("pass key to encrypt cookie value");
        return "key fail";
      }
    } else {
      cookieString = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';
    }
    if (expires) {
      if (typeof expires === 'number') {
        /** @type {?} */
        const dateExpires = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);
        cookieString += 'expires=' + dateExpires.toUTCString() + ';';
      } else {
        cookieString += 'expires=' + expires.toUTCString() + ';';
      }
    }
    if (path) {
      cookieString += 'path=' + path + ';';
    }
    if (domain) {
      cookieString += 'domain=' + domain + ';';
    }
    if (secure === false && sameSite === 'None') {
      secure = true;
      console.warn(`[ngx-secure-cookies] Cookie ${name} was forced with secure flag because sameSite=None.`);
    }
    if (secure) {
      cookieString += 'secure;';
    }
    cookieString += 'sameSite=' + sameSite + ';';
    document.cookie = cookieString;
    return true;
  }
  /**
   * @param {?} name   Cookie name
   * @param {?=} path   Cookie path
   * @param {?=} domain Cookie domain
   * @param {?=} secure
   * @param {?=} sameSite
   * @return {?}
   */
  delete(name, path, domain, secure, sameSite = 'Lax') {
    if (!this.documentIsAccessible) {
      return;
    }
    this.set(name, '', false, "", new Date('Thu, 01 Jan 1970 00:00:01 GMT'), path, domain, secure, sameSite);
  }
  /**
   * @param {?=} path   Cookie path
   * @param {?=} domain Cookie domain
   * @param {?=} secure
   * @param {?=} sameSite
   * @return {?}
   */
  deleteAll(path, domain, secure, sameSite = 'Lax') {
    if (!this.documentIsAccessible) {
      return;
    }
    /** @type {?} */
    const cookies = this.getAllCookies();
    for (const cookieName in cookies) {
      if (cookies.hasOwnProperty(cookieName)) {
        this.delete(cookieName, path, domain, secure, sameSite);
      }
    }
  }
  /**
   * @private
   * @param {?} name Cookie name
   * @return {?} property RegExp
   */
  getCookieRegExp(name) {
    /** @type {?} */
    const escapedName = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');
    return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
  }
  /**
   * @private
   * @param {?} encodedURIComponent
   * @return {?}
   */
  safeDecodeURIComponent(encodedURIComponent) {
    try {
      return decodeURIComponent(encodedURIComponent);
    } catch (_a) {
      return encodedURIComponent;
    }
  }
}
NgxEncryptCookieService.decorators = [{
  type: Injectable,
  args: [{
    providedIn: 'root'
  }]
}];
/** @nocollapse */
NgxEncryptCookieService.ctorParameters = () => [{
  type: InjectionToken,
  decorators: [{
    type: Inject,
    args: [PLATFORM_ID]
  }]
}];
/** @nocollapse */
NgxEncryptCookieService.ngInjectableDef = ɵɵdefineInjectable({
  factory: function NgxEncryptCookieService_Factory() {
    return new NgxEncryptCookieService(ɵɵinject(PLATFORM_ID));
  },
  token: NgxEncryptCookieService,
  providedIn: "root"
});
if (false) {
  /**
   * @type {?}
   * @private
   */
  NgxEncryptCookieService.prototype.documentIsAccessible;
  /**
   * @type {?}
   * @private
   */
  NgxEncryptCookieService.prototype.platformId;
}

/**
 * @fileoverview added by tsickle
 * Generated from: public-api.ts
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * Generated from: ngx-encrypt-cookie.ts
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

export { NgxEncryptCookieService };
