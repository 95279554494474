import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {

  user;

  constructor(
    private _apiService: ApiService,
    private _authService: AuthService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.user = this._authService.authenticated();
  }

  logout(){
    this._authService.logout();
  }
}
