import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Columns } from '../shared/models/columns.model';
import { ApiService } from '../shared/services/api/api.service';
import { query } from 'express';
import { AuthService } from '../shared/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bo',
  templateUrl: './bo.component.html',
  styleUrl: './bo.component.scss',
})
export class BoComponent implements OnInit {

  pageIndex = 0;
  filterByStatus = 0;
  sidebarVisible: boolean = true;
  sidebarRightVisible: boolean = false;
  menu!: MenuItem[];

  columns: Columns[] = Columns.createInitialData();
  selectedColumns!: Columns[];
  draggedColumn: Columns | null = null;
  user;
  constructor(
    private _apiService: ApiService,
    private _authService: AuthService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.selectedColumns = this.columns;
    this.initMenu();
    // this.getCustomerFile();
    if(!this._authService.authenticated()){
      this._router.navigate(["login"]);
    }
    this.user = this._authService.authenticated();
  }

  initMenu() {
    this.menu = [
      { label: 'Accueil' },
      {
        label: "Liste des dossiers d'identification",
        children: [
          { label: 'A saisir', command: () => this.switchStatus(1) },
          { label: 'A débloquer', command: () => this.switchStatus(2) },
          { label: 'A valider', command: () => this.switchStatus(2) },
          { label: 'Initialisés', command: () => this.switchStatus(3) },
          { label: 'Acceptés', command: () => this.switchStatus(4) },
          { label: 'Saisi', command: () => this.switchStatus(5) }
        ]
      },
      { label: 'Etat des activations' },
      { label: 'Tirage au sort' },
      { label: 'Gestions des utilisateurs' },
      { label: 'Gestions des agents' },
      { label: 'Gestions des clients' },
    ];
  }


  switchStatus(status: number) {
    this.filterByStatus = status;
    switch (status){
      // status = A saisir
      // status_trust&sign = Accepté
      case 0 : {
        this.customerFile = this.allCustomerFile.filter(file => file.id == this.filterByStatus);
        break;
      }
      // status = A débloquer
      // status_trust&sign = A débloqué
      case 1 : {
        this.customerFile = this.allCustomerFile.filter(file => file.id == this.filterByStatus);
        break;
      }
      // status = A validé
      // status_trust&sign = A validé
      case 2 : {
        this.customerFile = this.allCustomerFile.filter(file => file.id == this.filterByStatus);
        break;
      }
      // status = Initialisé
      // status_trust&sign = Initialisé
      case 3 : {
        this.customerFile = this.allCustomerFile.filter(file => file.id == this.filterByStatus);
        break;
      }
      // status = Accépté
      // status_trust&sign = Accepté
      case 4 : {
        this.customerFile = this.allCustomerFile.filter(file => file.id == this.filterByStatus);
        break;
      }
      // status = Saisie
      case 5 : {
        this.customerFile = this.allCustomerFile.filter(file => file.id == this.filterByStatus);
        break;
      }
      default : {
        this.customerFile = this.allCustomerFile;
        break;
      }
    }

  }

  onSidebarShow(){}
  onSidebarHide(){}
  switchPage(index: number) {
    this.pageIndex = index;
  }

  customerFile: any = [];
  allCustomerFile: any = [];
  getCustomerFile(){
    this._apiService.get('browse/customer-files').subscribe((resp: any) =>{
      this.allCustomerFile = resp.data;
    })
  }

  goToPage(page){
    this._router.navigate([page]);
  }
}
